/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          description
          author {
            name
            summary
          }
          social {
            twitter
            instagram
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const desc = data.site.siteMetadata?.description
  const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      {/* <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/profile-pic.png"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
      /> */}
      
        <p>
          {desc}
          <br />
          <a href={`https://twitter.com/${social?.twitter || ``}`}>Twitter</a>&nbsp;
          <a href={`https://instagram.com/${social?.instagram || ``}`}>Instagram</a>&nbsp;
          <a href={`https://facebook.com/${social?.facebook || ``}`}>Facebook</a>&nbsp;
        </p>
  
    </div>
  )
}

export default Bio
